import { useEffect, useState } from 'react'
import _ from 'lodash';
import './Gallery.scss'

export default function Gallery({ content, portfolioItemData }) {

  const Row = (data) => {

    return (
      <div className={`gallery-row row-${data.data.row.length} ${data.data.offset ? "offset-" + data.data.offset_side : ''} ${data.data.remove_height_restrictions ? "height-reset" : ''}`}>
        {data.data.row.map((image, index) => (
          <Image data={image} key={index} />
        ))}
      </div>
    )
  }

  const Image = (data) => {
    return (
      <div className={`gallery-image ${data.data.contain ? 'image-contain' : ''}`}>
        <img src={data.data.gallery_image} />
      </div>
    )
  }

  if (!content) return null;

  return (
    <section className="portfolio-gallery">
      <div className="container">
        {content.gallery.map((row, index) => (
          <Row data={row} key={index}/>
        ))}
      </div>
    </section>
  )
}
