import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react'
import './Newsletter.scss'

import Title from './components/Title/Title';
import NewsletterDisplay from './components/NewsletterDisplay/NewsletterDisplay';

export default function Newsletter() {

  return (
    <>
      <Helmet bodyAttributes={{ class: 'newsletter' }}>
        <title>Newsletter | Third Rail Creative</title>
        <meta name="description" content="Helmet application" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"></meta>
      </Helmet>

      <Title />
      <NewsletterDisplay />

    </>
  )
}
