import { useEffect, useState, useContext } from 'react'
import { useLocation, NavLink, Link } from 'react-router-dom';
import { OptionsContext } from 'OptionsContext';
import './Footer.scss'

import Pole from 'assets/footer-pole.svg';

export default function Footer() {
  const options = useContext(OptionsContext);

  if (!options) return null;

  return (
    <footer className="primary-footer">
      <div className="container">
        <div className="content-container">
          <div className="contact">
            <h3>Third Rail Creative</h3>
            <div className="details">
              <p className="phone"><a href="tel:5123589907">(512) 358-9907</a></p>
              <p className="address"><a href="https://www.google.com/maps/place/716+Congress+Ave+%23200,+Austin,+TX+78701/@30.269725,-97.744848,17z/data=!3m1!4b1!4m5!3m4!1s0x8644b509f15cedeb:0x42cf3176a8e4dac2!8m2!3d30.269725!4d-97.742654" target="_blank">716 Congress Ave. #200<br/> Austin, TX 78701</a></p>
            </div>
          </div>
          <div className="social">
            <div className="links">
              {options.social_links.map(link => (
                <a href={link.link.url} key={link.link.title} target="_blank">
                  <img src={link.icon.url} alt={link.link.title} />
                  <span>{link.link.title}</span>
                </a>
              ))}
            </div>
            <img src={Pole} alt="Third Rail Creative" className="dec" />
          </div>
        </div>
      </div>
    </footer>
  )
}
