
import { useEffect, useState } from 'react'
import { API_URL } from 'assets';
import './Title.scss'

import PageTitle from './../../assets/3rd.png';
import PageTitleStacked from './../../assets/3rd-stacked.png';

export default function Title() {

  return (
    <section className="newsletter-title">
      <div className="container">
        <h1>
          <img src={PageTitle} alt="Third Rail Dispatch" className="desktop" />
          <img src={PageTitleStacked} alt="Third Rail Dispatch" className="mobile" />
        </h1>
        <p>The latest and greatest from 3RC</p>
        <div className="featured-tag work">New work</div>
        <div className="featured-tag culture">Agency culture</div>
        <div className="featured-tag people">Employee news</div>
      </div>
    </section>
  )
}
