import React, { useRef, useEffect, useState } from 'react';
import './OverviewMobile.scss'

import DownArrow from './../assets/down-arrow-white.svg';

export default function OverviewMobile() {

  return (
    <section className={`overview-mobile`}>
      <div className="container">
        <h2>Begin exploring to reveal our selected charities</h2>
        <div className="scroll-message">
          <p>Start scrolling</p>
          <div className="arrow-indicator">
            <img src={DownArrow} />
          </div>
        </div>
      </div>
    </section>
  )
}
