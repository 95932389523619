import Hero from './Hero/Hero'
import CopyBlock from './CopyBlock/CopyBlock'
import Gallery from './Gallery/Gallery'
import AnnotatedImage from './AnnotatedImage/AnnotatedImage'
import BrandingBanner from './BrandingBanner/BrandingBanner'
import MotionGraphicFeature from './MotionGraphicFeature/MotionGraphicFeature'
import Video from './Video/Video'
import DesktopComp from './DesktopComp/DesktopComp'
import MobileComps from './MobileComps/MobileComps'
import BeforeAndAfter from './BeforeAndAfter/BeforeAndAfter'
import BlockQuote from './BlockQuote/BlockQuote'
import LiveView from './LiveView/LiveView'

import { useEffect, useState } from 'react'


export default function Component({ component, portfolioItemData }) {
  const components = {
    hero: Hero,
    copy_block: CopyBlock,
    gallery: Gallery,
    annotated_image: AnnotatedImage,
    branding_banner: BrandingBanner,
    motion_graphic_feature: MotionGraphicFeature,
    video: Video,
    desktop_comp: DesktopComp,
    mobile_comps: MobileComps,
    before_and_after: BeforeAndAfter,
    block_quote: BlockQuote,
    live_view: LiveView,
  }
  const Component = components[component.acf_fc_layout];

  if (Component) {
    return (
      <Component content={component} portfolioItemData={portfolioItemData}/>
    )
  }

  return null;
}
