import React, { useRef, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { isMobile, isTablet, isBrowser } from 'react-device-detect';
import './index.scss'

import Intro from './Intro/Intro'
import Overview from './Overview/Overview'
import Slides from './Slides/Slides'

import IntroMobile from './IntroMobile/IntroMobile'
import OverviewMobile from './OverviewMobile/OverviewMobile'
import CharitiesMobile from './CharitiesMobile/CharitiesMobile'

import Arrow from './assets/intro-arrow.svg';

export default function HolidayGift() {
  const [mobileDisplay, setMobileDisplay] = useState(false);
  const [isActivated, setIsActivated] = useState(false);

  const handleButtonClick = () => {
    setIsActivated(true);
  }

  useEffect(() => {
    const content = document.querySelector('.holiday-gift');
    if (isActivated) {
      content.style.transform = `translateY(-$100vh)`;
    }
  }, [isActivated])



  useEffect(() => {
    // Check the window width on mount and resize
    const handleResize = () => {
      setMobileDisplay(window.innerWidth < 1025); // You can adjust the threshold for mobile view
    };

    // Initial check on mount
    handleResize();

    // Listen for window resize events
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Helmet bodyAttributes={{ class: `holiday-gift front 2023 ${mobileDisplay || isMobile || isTablet? "mobile" : "desktop"}` }}>
        <title>Third Rail Creative</title>

        <meta name="description" content="Helmet application" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"></meta>
      </Helmet>

        {mobileDisplay || isMobile || isTablet ?
        <>
            <IntroMobile />
            <OverviewMobile />
            <CharitiesMobile />
          </>
          :
          <>
            <div className={`front-container ${isActivated ? "activated" : ""}`}>
              <Intro />
              <Overview />
              <button className="button" onClick={handleButtonClick}>
                <img src={Arrow} alt="Begin experience" />
                Click to begin
              </button>
            </div>
            <div className="resize-message">
              <p>This experience works better in a larger viewing area. Please resize your window or refresh the page.</p>
            </div>
          </>
        }
    </>
  )
}
