import { useEffect, useState } from 'react'
import _ from 'lodash';
import './Hero.scss'


export default function Hero({ data }) {

  const [heroContent, setHeroContent] = useState();

  useEffect(() => {
    const filteredContent = data.acf.content.filter(item => item.acf_fc_layout === 'hero');
    setHeroContent(filteredContent);
  }, [data])

  if (!heroContent) return null;

  return (
    <section className="portfolio-hero" style={{ backgroundImage: `url(${heroContent[0].background_image})`}}>
      <div className="container">
        <div className="content">
          <h1>{heroContent[0].header}</h1>
          <h2>{heroContent[0].subheader}</h2>
        </div>
      </div>
    </section>
  )
}
