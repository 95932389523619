import { useEffect, useState } from 'react'
import _ from 'lodash';
import './Contact.scss'

import BackArrow from 'assets/arrow-left.svg';

export default function Contact() {

  return (
    <section className="portfolio-item-contact">
      <div className="container">
        <div className="back-to-projects">
          <a href="/work"><img src={ BackArrow } alt="back to projects" /> Back to projects</a>
        </div>
        <div className="message">
          <p>Interested in partnering with us?<br /> <a href="https://thirdrailcreative.com/contact">We’d love to hear from you!</a></p>
        </div>
      </div>
    </section>
  )
}
