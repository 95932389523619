import { useEffect, useState } from 'react'
import './Experience.scss'


export default function Experience({ data }) {

  const [experienceContent, setExperienceContent] = useState();

  useEffect(() => {
    const filteredContent = data.acf.content.filter(item => item.acf_fc_layout === 'clients_experience');
    setExperienceContent(filteredContent);
  }, [data])

  if (!experienceContent) return null;

  return (
    <section className="clients-experience">
      <div className="container">
        <h2>Client Experience</h2>
        <div className="clients-wrapper">
          {experienceContent[0].clients.map(client => (
            <div className="client" key={client.name}>
              <img src={`${client.logo}`} alt={`${client.name} logo`} />
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
