import { React } from 'react'
import { Navigate, Routes, Route } from 'react-router-dom';
import { OptionsContextProvider } from './OptionsContext';

// Components
import Header from './Components/Header/Header'
import Footer from './Components/Footer/Footer'

// Pages
import Home from './pages/Home/Home'
import Portfolio from './pages/Portfolio/Portfolio'
import PortfolioItem from './pages/PortfolioItem/PortfolioItem'
// import TestPortfolio from './pages/Portfolio/TestPortfolio'
import CaseStudy from 'pages/CaseStudy/CaseStudy';
import CaseStudyItem from 'pages/CaseStudyItem/CaseStudyItem';
import Contact from 'pages/Contact/Contact';
import Company from './pages/Company/Company';
import JobPosting from 'pages/JobPosting/JobPosting';
import Culture from './pages/Culture/Culture';
import Newsletter from 'pages/Newsletter/Newsletter';
import HolidayGift from 'pages/HolidayGift/HolidayGift';
import HolidayGift2023 from 'pages/HolidayGift2023/index';
import HolidayGiftSlides from 'pages/HolidayGift2023/Slides/Slides';

import CapabilitiesEmbed from 'pages/CapabilitiesEmbed/CapabilitiesEmbed';

function App() {
  return (
    <>
      <OptionsContextProvider>

        <Header />

        <Routes>
          <Route path="/" element={<Navigate to='work' />} />
          {/* <Route path="/" element={<Home />} /> */}
          <Route path="work" element={<Portfolio />} />
          <Route path="work/:slug" element={<PortfolioItem />} />
          {/* <Route path="case-studies" element={<CaseStudy />} />
          <Route path="case-studies/:slug" element={<CaseStudyItem />} />
          <Route path="capabilities" element={<CapabilitiesEmbed />} />
          <Route path="company" element={<Company />} />
          <Route path="careers/:slug" element={<JobPosting />} />
          <Route path="culture" element={<Culture />} />
          <Route path="contact" element={<Contact />} /> */}
          <Route path="newsletter" element={<Newsletter />} />
          {/* <Route path="holiday-2022" element={<HolidayGift />} /> */}
          <Route exact path="holiday" element={<HolidayGift2023 />} />
          <Route path="holiday/slides" element={<HolidayGiftSlides />} />

          {/* <Route path="portfoliotest" element={<TestPortfolio />} />  */}

          <Route path="*" element={<Navigate to='work' />} />
        </Routes>

        <Footer />

      </OptionsContextProvider>
    </>
  );
}

export default App;
