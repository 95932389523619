import { useEffect, useState } from 'react'
import './AnnotatedImage.scss'


export default function AnnotatedImage({ content, portfolioItemData }) {

  if (!content) return null;

  return (
    <section className={`portfolio-annotated-image ${content.positioning}`}>
      <div className="container">
        <p className="copy">{content.copy}</p>
        <img src={content.image} alt={content.copy} className="image" />
      </div>
    </section>
  )
}
