import { useEffect, useState } from 'react'
import _ from 'lodash';
import './Hero.scss'

export default function Hero({ content, portfolioItemData }) {
  const [services, setServices] = useState([]);

  useEffect(() => {
    const filtered = portfolioItemData && portfolioItemData._embedded['wp:term'] ? _.flatten(portfolioItemData._embedded['wp:term']).filter(service => service.taxonomy === "service") : []
    setServices(filtered)
  }, [portfolioItemData])

  if (!content) return null;

  return (
    <section className="portfolio-item-hero">
      <div className="featured-image">
        <img src={content.featured_image.url} />
      </div>
      <div className="container">
        <div className="hero-content">
          <div className="services-provided">
            {services.map(service => (
              <div className="service" key={service.name}>
                <span dangerouslySetInnerHTML={{ __html: service.name }}></span>
              </div>
            ))}
          </div>
          <h1 dangerouslySetInnerHTML={{ __html: portfolioItemData.title.rendered }}></h1>
          {portfolioItemData.content.rendered ?
            <div className="project-background">
              <span className="label">Background</span>
              <p dangerouslySetInnerHTML={{ __html: portfolioItemData.content.rendered }}></p>
            </div>
            : null
          }
        </div>
      </div>
    </section>
  )
}
