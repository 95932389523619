import React, { useRef, useEffect, useState } from 'react';
import './Intro.scss'

import Logo from './../assets/3rc-logo.png';
import Brand from './../assets/3rc-brand.svg';

export default function HolidayGift() {
  return (
    <section className="intro">
      <img className="logo" src={Logo} />
      <div className="container">
        <div className="gift-tag">
          <div className="border">
            <div className="title">
              <h1>Join us this season of giving</h1>
            </div>
            <div className="intro-copy">
              <p>Dear Friends,<br></br><br></br> As we reflect on a wonderful 2023 at Third Rail Creative, we're filled with gratitude and excited for what’s to come. Thanks to you, our incredible clients, we're in the fortunate position to give back to our community this holiday season.<br></br><br></br> Please join Third Rail Creative in spreading holiday cheer by helping support six meaningful Austin charities handpicked by our team. <br></br><br></br> <strong>Simply choose your preferred charity, and Third Rail Creative will make a donation on your behalf.</strong> <br></br><br></br> We hope you enjoy getting to know why each of these six charities matters so much to our team. Together, we can bring joy and make a real difference in the lives of others.<br></br><br></br> -Your Friends at Third Rail Creative
              </p>
            </div>
            </div>
          </div>
      </div>
      <img className="brand" src={Brand} />
    </section>
  )
}
