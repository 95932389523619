import { useEffect, useState } from 'react'
import './CopyBlock.scss'


export default function CopyBlock({ content, portfolioItemData }) {

  useEffect(() => {
    console.log(content);
  }, [content])

  if (!content) return null;

  return (
    <section className="portfolio-copy-block">
      <div className="container">
        <p className="headline" style={{ textAlign: content.alignment, color: content.custom_color ? content.custom_color : '#000'}}>{content.headline}</p>
        <div className="body" style={{ textAlign: content.alignment, color: content.custom_color ? content.custom_color : '#000' }} dangerouslySetInnerHTML={{__html: content.copy}}></div>
      </div>
    </section>
  )
}
