
import { useEffect, useState } from 'react'
import useWindowSize from 'hooks/useWindowSize';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './AccordionCarousel.scss'

export default function AccordionCarousel({ content }) {

  const contentItems = content.accordions;

  const [selectedItem, setSelectedItem] = useState(contentItems[0]);
  const windowSize = useWindowSize();
  const isDesktop = windowSize.width >= 768;

  const selectAccordion = (accordion) => {
    setSelectedItem(accordion);
  }

  return (
    <div className="accordion-carousel">
      {isDesktop ?
        <div className="desktop">
          <div className="accordion">
            {/* <h3 dangerouslySetInnerHTML={{ __html: content.accordion_subheader }}></h3>
            <p dangerouslySetInnerHTML={{ __html: content.accordion_body_copy }} className="subheader"></p> */}
            <div className="title">
                {content.subheader ?
                  <h3 dangerouslySetInnerHTML={{__html: content.subheader}}></h3>
                : null
                }
                {content.body_copy ?
                  <p dangerouslySetInnerHTML={{__html: content.body_copy}}></p>
                : null
                }
              </div>
            <div className="accordion-container">
              {contentItems.map((accordion, index) => (
                <div className={`accordion-item ${selectedItem.label === accordion.label ? "active" : ""}`} key={accordion.label}>
                  <h4 dangerouslySetInnerHTML={{ __html: accordion.label }} onClick={()=> selectAccordion(accordion)}></h4>
                  <p dangerouslySetInnerHTML={{__html: accordion.body_copy}}></p>
                </div>
              ))}
            </div>
          </div>
          <div className="image">
            {contentItems.map((accordion, index) => (
                <img src={accordion.image.url} alt={accordion.label} className={`accordion-image ${selectedItem.label === accordion.label ? "active" : ""}`} key={`image-${accordion.label}`}/>
            ))}
          </div>
        </div>
        :
        <div className="mobile">
          <div className="carousel">
            <h3 dangerouslySetInnerHTML={{ __html: content.subheader }}></h3>
            <p dangerouslySetInnerHTML={{ __html: content.body_copy }} className="subheader"></p>

            <Carousel showArrows={false} showStatus={false} infiniteLoop={true} autoPlay={false}>
              {contentItems.map((accordion, index) => (
                <div className={`accordion-item ${selectedItem.label === accordion.label ? "active" : ""}`} key={accordion.label}>
                    <div className="image">
                        <img src={accordion.image.url} alt={accordion.label} className={`accordion-image ${selectedItem.label === accordion.label ? "active" : ""}`} key={`image-${accordion.label}`}/>
                    </div>
                    <h4 dangerouslySetInnerHTML={{ __html: accordion.label }}></h4>
                    <p dangerouslySetInnerHTML={{__html: accordion.body_copy}}></p>
                  </div>
                ))}
            </Carousel>
          </div>
        </div>
      }
    </div>
  )
}
