import { useEffect, useState } from 'react'
import _ from 'lodash';
import './MotionGraphicFeature.scss'

export default function MotionGraphicFeature({ content, portfolioItemData }) {

  useEffect(() => {
    console.log(content);
  }, [])

  if (!content) return null;

  return (
    <section className={`portfolio-motion-graphic-feature ${content.extra_spacing ? 'extra-spacing' : ''}`} style={{ backgroundColor: content.background_color }}>
      {content.video_contained ?
        <div className="container">
          <video muted playsInline autoPlay loop>
            <source src={content.video_file_mp4.url} type="video/mp4; codecs=hvc1" />
            <source src={content.video_file_webm.url} type="video/webm" />
          </video>
        </div>
        :
        <video muted playsInline autoPlay loop>
          <source src={content.video_file_mp4.url} type="video/mp4; codecs=hvc1" />
          <source src={content.video_file_webm.url} type="video/webm" />
        </video>
      }
    </section>
  )
}
