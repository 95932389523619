import { useEffect, useState } from 'react'
import _ from 'lodash';
import './BlockQuote.scss'

import OpenQuote from 'assets/quote.svg';

export default function BlockQuote({ content, portfolioItemData }) {

  if (!content) return null;

  return (
    <section className="portfolio-block-quote">
      <div className="container">
        <div className="quote-image">
          <img src={content.quote_author_image}/>
        </div>
        <div className="quote-content">
          <p className="quote">{content.quote}</p>
          <div className="author">
            <p>{content.quote_author}, {content.quote_author_title},<br /> <em>{content.quote_author_affiliation}</em></p>
          </div>
        </div>
      </div>
    </section>
  )
}
