import { useEffect, useState, useContext } from 'react'
import { useLocation, NavLink, Link } from 'react-router-dom';
import { OptionsContext } from 'OptionsContext';
import './Header.scss'

import NavArrow from 'assets/arrow_nav_children.svg';


export default function Header() {
  const location = useLocation();
  const options = useContext(OptionsContext);
  const [collapsedSubnavItems, setCollapsedSubnavItems] = useState([]);
  const [menuActive, setMenuActive] = useState(false);

  const toggleSubnavCollapse = name => {
    if (collapsedSubnavItems.includes(name)) {
      setCollapsedSubnavItems(collapsedSubnavItems.filter(itemName => itemName !== name));
    } else {
      setCollapsedSubnavItems([...collapsedSubnavItems, name]);
    }
  };

  const toggleMenu = () => {
    setMenuActive(prevMenuActive => !prevMenuActive);
  }

  if (!options) return null;

  return (
    <header className="primary-header">
      <div className="container">
        <div className="content-container">
          <div className="logo">
            <a href="https://thirdrailcreative.com">
              {location.pathname === "/" ?
                <img src={options.logo_secondary.url} alt="Third Rail Creative" />
                :
                <img src={options.logo_primary.url} alt="Third Rail Creative" />
              }
            </a>
          </div>
          <div className="mobile-menu-toggle">
            <button onClick={toggleMenu}>☰</button>
          </div>
          <nav className={`primary-nav ${menuActive ? 'active' : ''}`}>
            <ul className="nav-items">
              {options.primary_nav.map(item => (
                <li className="nav-item" key={item.link.title}>
                  {item.children.length ?
                    <a className="dropdown" onClick={ () => toggleSubnavCollapse(item.link.title)}>{item.link.title}
                      <img src={NavArrow} className={`${location.pathname === "/" ? "white" : ""}`}/>
                    </a>
                    :
                    <a href={item.link.url}>{item.link.title}</a>
                  }
                  {item.children.length ?
                    <ul className={`nav-item-children ${collapsedSubnavItems.includes(item.link.title) ? 'active' : ''}`}>
                      {item.children.map(child => (
                        <li className="nav-item-child" key={child.link.title}><a href={child.link.url} className="child-link">{child.link.title}</a></li>
                      ))}
                    </ul>
                    : null
                  }
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </header>
  )
}
