import { useEffect, useState } from 'react'
import './Contact.scss'


export default function Contact({ data }) {

  const [contactContent, setContactContent] = useState();

  useEffect(() => {
    const filteredContent = data.acf.content.filter(item => item.acf_fc_layout === 'contact_message');
    setContactContent(filteredContent);
  }, [data])

  if (!contactContent) return null;

  return (
    <section className="portfolio-contact">
      <div className="container">
        <p>{contactContent[0].message}</p>
        <a href={contactContent[0].cta.url}>{contactContent[0].cta.title}</a>
      </div>
    </section>
  )
}
