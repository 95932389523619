import { useEffect, useState } from 'react'
import _ from 'lodash';
import './BrandingBanner.scss'

export default function Gallery({ content, portfolioItemData }) {

  if (!content) return null;

  return (
    <section className={`portfolio-branding-banner ${content.display_type} ${content.extra_space ? "extra-space" : ''}`} style={{backgroundColor: `${content.custom_background ? content.custom_background : '#fff'}`}}>
      <img src={content.branding_image} />
    </section>
  )
}
