import { useEffect, useState } from 'react'
import _ from 'lodash';
import './Video.scss'

export default function Video({ content, portfolioItemData }) {

  if (!content) return null;

  return (
    <section className="portfolio-video">
      <div className="container">
        <div className="video-container-responsive">
          <iframe src={`https://player.vimeo.com/video/${content.vimeo_video_id}?h=39bb03315f`} width="640" height="360" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
        </div>
      </div>
    </section>
  )
}
