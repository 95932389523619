
import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import './Team.scss'

Modal.setAppElement('#root');

export default function Team({ data }) {
  const [teamList, setTeamList] = useState([]);
  const [selectedTeamMember, setSelectedTeamMember] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: '90vw',
    },
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const handleChildClick = (childInfo) => {
    setSelectedTeamMember(childInfo);
    openModal();
  }

  const TeamMember = ({ data, selection }) => {
    return (
      <div className="team-member" onClick={() => selection(data)}>
        <div className="headshot" style={{ backgroundImage: `url(${data.headshot.url})` }} />
        <h4>{data.name}</h4>
        <p>{data.job_title}</p>
      </div>
    )
  }

  useEffect(() => {
    const shuffledData = [...data.team].sort(() => Math.random() - 0.5);
    setTeamList(shuffledData);
  }, [data])

  useEffect(() => {
    console.log(selectedTeamMember);
  }, [selectedTeamMember])

  if (!data) return null;

  return (
    <section className="team" id="team">
      <div className="container">
        <h2>{data.headline}</h2>
        <div className="team-member-display">
          {teamList.map((teammember, index) => (
            <TeamMember data={teammember} selection={handleChildClick} key={index}/>
          ))}
        </div>
        { modalIsOpen ?
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <button className="close" onClick={closeModal}>close</button>
            <div className="modal-container">
              <div className="headshot">
                <img src={selectedTeamMember.headshot.url} alt={selectedTeamMember.name} />
              </div>
              <div className="team-member-info">
                <h4>{selectedTeamMember.name}</h4>
                <p className="job-title">{selectedTeamMember.job_title}</p>
                <p className="bio" dangerouslySetInnerHTML={{__html: selectedTeamMember.bio}}></p>
              </div>
            </div>
          </Modal>
          : null }
      </div>
    </section>
  )
}
