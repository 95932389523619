import { useEffect, useState } from 'react'
import './LiveView.scss'

import Arrow from 'assets/arrow-up-right.svg'

export default function LiveView({ content, portfolioItemData }) {

  if (!content) return null;

  return (
    <section className="portfolio-live-view">
      <div className="container">
        <a className="cta" href={content.link_to_project.url} target="_blank">See this project live <img src={Arrow} alt="View project"/></a>
      </div>
    </section>
  )
}
