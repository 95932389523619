import React, { useRef, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import firebase from './../firebase';
import './Slides.scss'

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import contentData from './../data';

import Next from './../assets/next.svg';
import Close from './../assets/close-orange.svg';
import ExpandCollapse from './../assets/expand-collapse.svg';
import Quote from './../assets/quotation-mark.svg';

export default function Slides() {
  const firestore = firebase.firestore();
  const history = useNavigate();

  const charityData = contentData;

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 99,
    }
  };

  const [fadeIn, setFadeIn] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);
  const [activeSlideDecay, setActiveSlideDecay] = useState(false);
  const [scrollProgress, setScrollProgress] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [direction, setDirection] = useState(null);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [name, setName] = useState('');
  const [selectedCharity, setSelectedCharity] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(null);
  const [employeeSectionActive, setEmployeeSectionActive] = useState(false);

  const handleScroll = (e) => {
    if (e.deltaY < 0) {
      setScrollPosition((prevScrollPosition) => Math.max(0, prevScrollPosition - 1));
      setDirection("backwards");
    } else if (e.deltaY > 5) {
      setScrollPosition((prevScrollPosition) => prevScrollPosition + 1);
      setDirection("forwards");
    }
  };

  const handleChange = e => {
    setName(e.target.value);
  };

  const submitDonation = (e) => {
    e.preventDefault();
    setSubmitStatus(true);

    firestore.collection('holiday-gift-2023').add({
      name: name,
      donation: selectedCharity.name,
    });
  }

  const employeeToggle = () => {
    setEmployeeSectionActive((prevEmployeeSectionActive) => !prevEmployeeSectionActive);
  }

  const clickToPrev = () => {
    setActiveSlide((prevActiveSlide) => prevActiveSlide - 1);
  }

  const clickToNext = () => {
    setActiveSlide((prevActiveSlide) => prevActiveSlide + 1);
  }


  const closeModal = () => {
    setModalIsOpen(false);
    setSubmitStatus(false);
    setSelectedCharity(null);
  }

  //Event listener for scroll
  // useEffect(() => {
  //   window.addEventListener('wheel', handleScroll);
  //   return () => {
  //     window.removeEventListener('wheel', handleScroll);
  //   };
  // }, []);


  //Handles scrollProgress bar
  useEffect(() => {
    const newScrollProgress = scrollPosition * 1.75;
    const clampedScrollProgress = Math.max(0, Math.min(100, newScrollProgress)); // Ensure scrollProgress stays within the range [0, 100]
    setScrollProgress(clampedScrollProgress);
  }, [scrollPosition])

  //Handles slide change. Resets scrollbar and scroll position states
  useEffect(() => {
    setScrollProgress(5);
    setScrollPosition(0);
    const content = document.querySelector('.horizontal-scroll-content');
    if (activeSlide > 0  && activeSlide <= 6) {
      content.style.transform = `translateX(-${activeSlide}00vw)`;
      setActiveSlideDecay(false);
      setEmployeeSectionActive(false);
    } else if (activeSlide === 0) {
      content.style.transform = `translateX(0px)`;
    }
  }, [activeSlide])

  //Handles activeslide
  useEffect(() => {
    if (scrollProgress === 100) {
      setActiveSlide((prevActiveSlide) => prevActiveSlide + 1);
    } else if (activeSlide > 0 && scrollProgress === 0 && direction === "backwards") {
      setActiveSlide((prevActiveSlide) => prevActiveSlide - 1);
    } else if (activeSlide === 0 && scrollProgress === 0 && direction === "backwards") {
        history('/holiday');
    }

    if (scrollProgress > 75 && direction === "forwards") {
      setActiveSlideDecay(true);
    } else {
      setActiveSlideDecay(false);
    }
  }, [scrollProgress])

  useEffect(() => {
    setFadeIn(true);
  }, []);


  return (
    <>
      <Helmet bodyAttributes={{ class: 'holiday-gift 2023' }}>
        <title>Third Rail Creative</title>

        <meta name="description" content="Helmet application" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"></meta>
      </Helmet>

      <div className={`horizontal-scroll-page active`}>
        <div className="horizontal-scroll-content">
          {charityData.map((charity, index) => (
            <div key={charity.name} className={`slide slide-${index + 1} ${index === 0 && index === activeSlide && fadeIn ? 'fade-in' : ''} ${index === activeSlide ? "active" : ""} ${activeSlideDecay && index === activeSlide ? "decay" : ""}`}>
              {index !== 0 ?
                <div className="prev-slide" onClick={clickToPrev}>
                  <img src={Next} />
                  <p>Previous</p>
                </div>
                : null
              }
              <div className="next-slide" onClick={clickToNext}>
                {index === 5 ?
                  <p>Choose</p>
                :
                  <p>Next</p>
                }
                <img src={Next} />
              </div>
              <div className="content">
                <div className="slide-info">
                  <span className="index"><p>{index + 1}</p></span>
                  <h3>{charity.name}</h3>
                </div>
                <div className="illustration">
                  <img src={charity.image} alt={charity.name} className="foreground" />
                  <img src={charity.bgImage} alt={charity.name + 'background'} className="background"/>
                </div>
              </div>
              <div className={`employee-testimonials ${employeeSectionActive ? "active" : "inactive"}`}>

                {employeeSectionActive ?
                  <div className="hide-employees" onClick={employeeToggle}><p>Close</p></div>
                  : null
                }
                <img src={ExpandCollapse} className={`expand-collapse ${employeeSectionActive ? "active" : "inactive"}`}  onClick={employeeToggle}/>
                {!employeeSectionActive ?
                  <div className="expand-employees" onClick={employeeToggle}>
                  <p>
                    <span className="underline">Click</span> to see why {charity.employees.map((employee, index) => (
                      <span key={index}>
                        {employee.name}
                        {index === charity.employees.length - 1 ? '' : index === charity.employees.length - 2 ? ', and ' : ', '}
                      </span>
                    ))} care about {charity.name}.
                  </p>
                </div>
                  : null
                }
                <div className={`employee-display ${employeeSectionActive ? "active" : "inactive"}`}>
                  {charity.employees.map(employee => (
                    <div className={`employee`}>
                      <div className="title"><p>{employee.title}</p></div>
                      <div className="name"><p>{employee.name}</p></div>
                      <div className="quote"><p><img src={Quote}/>{employee.quote}"</p></div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="employee-testimonials-bg"></div>
              <div className="progress-bar" style={{ width: `${scrollProgress}%` }}></div>
            </div>
          ))
          }
          <div className="slide slide-7">
            <div className="container">
              <h2>Select your charity of choice and enter your name.<br></br> We'll make a donation to them on your behalf.</h2>
              <div className="game-board">
                {charityData.map((charity, index) => (
                  <div className={`option option-${index + 1}`} onClick={() => { setModalIsOpen(true); setSelectedCharity(charity) }}>
                    <div key={charity.name} className={`circle-dec`}>
                      <img src={charity.boardImage} />
                    </div>
                    <span className="label">{charity.name}</span>
                    {/* <img className="board-img" src={charity.boardImage} onClick={() => { setModalIsOpen(true); setSelectedCharity(charity) }} /> */}
                  </div>
                ))}
              </div>
            </div>

            {selectedCharity ?
              <Modal
                  isOpen={modalIsOpen}
                  onRequestClose={closeModal}
                  style={customStyles}
                  contentLabel="Example Modal"
                  ariaHideApp={false}
                  className="modal"
                  backdrop="static"
                  shouldCloseOnOverlayClick={false}
              >
                {!submitStatus ?
                  <>
                    <button onClick={closeModal} className="close"><img src={Close} alt="Close modal" /></button>
                    <div className="modal-content">
                      <h3>THANK YOU</h3>
                      <div className="selection">
                        <div className="illustration">
                          <img src={selectedCharity.bgImage} />
                          <img src={selectedCharity.image} />
                        </div>
                        <div className="selection-name">
                          <p>{selectedCharity.name}</p>
                        </div>
                      </div>
                      <form>
                        <label>
                          Donation by
                            <input type="text" name="name" placeholder="Enter your name or leave blank" onChange={handleChange} />
                          </label>
                          <input type="submit" value="Donate" className="submit-donation" onClick={submitDonation} />
                        </form>
                    </div>
                  </>
                  :
                  <>
                    <div className="modal-content">
                    <div className="selection">
                        <div className="illustration">
                          <img src={selectedCharity.bgImage} />
                          <img src={selectedCharity.image}/>
                        </div>
                        <div className="selection-name">
                          <p>{selectedCharity.name}</p>
                        </div>
                      </div>
                      <h3>Thank you.</h3>
                      <p>Third Rail Creative will be placing a donation on your behalf! <br></br><br></br> With gratitude and holiday cheer,<br></br><br></br> Your Friends at Third Rail Creative</p>
                      <a className="exit-experience" href="https://thirdrailcreative.com/">Close</a>
                    </div>
                  </>
                }
              </Modal>
              : null
            }

          </div>
        </div>
        <div className="resize-display">
          <div className="container">
            <p>This experience is better on a desktop screen.<br></br>Please resize your window and continue.</p>
          </div>
        </div>
      </div>

    </>
  )
}
