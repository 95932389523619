import React, { useRef, useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { useNavigate } from 'react-router-dom';
import './Overview.scss'

import StartScrolling from './../assets/next.svg';

import KeepAustinBeautiful from './../assets/c1-white-outline.svg';
import InsideBooksProject from './../assets/c2-white-outline.svg';
import CentralTexasFoodBank from './../assets/c3-white-outline.svg';
import Lifeworks from './../assets/c4-white-outline.svg';
import AustinPetsAlive from './../assets/c5-white-outline.svg';
import RonaldMcDonaldHouse from './../assets/c6-white-outline.svg';

export default function Overview() {
  const [overviewActive, setOverviewActive] = useState(false);
  const scrollContainerRef = useRef();
  const history = useNavigate();
  const [scrollY, setScrollY] = useState(0);
  const [autoClickCounter, setAutoClickCounter] = useState(0)

  const circleProps = useSpring({
    transform: `translate(-50%, -50%) scale(${Math.min(1, (scrollY + 7) / 300)})`,
    top: `${Math.min(50, (scrollY + 55) / 2)}%`,
    left: `${Math.min(50, (scrollY + 50) / 2)}%`,
  });



  const handleScroll = (e) => {
    if (e.deltaY < 0) {
      setScrollY((prevScrollY) => Math.max(0, prevScrollY - 3));
    } else if (e.deltaY > 0) {
      setScrollY((prevScrollY) => prevScrollY + 3);
    }
  };

  const handleAutoClick = () => {
    setAutoClickCounter((prevAutoClickCounter) => prevAutoClickCounter + 1);
  };

  useEffect(() => {
    if (autoClickCounter > 0) {
      handleAutoClick();
      setScrollY((prevScrollY) => prevScrollY + 0.03);
    }
  }, [autoClickCounter])

  // useEffect(() => {
  //   if (overviewActive) {
  //     window.addEventListener('wheel', handleScroll);

  //     return () => {
  //       window.removeEventListener('wheel', handleScroll);
  //     };
  //   }
  // }, [overviewActive]);

  useEffect(() => {
    const options = {
      root: null, // Use the viewport as the root
      rootMargin: '0px',
      threshold: 1, // Trigger when 10% of the element is visible
    };

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && entries[0].boundingClientRect.top === 0) {
        setOverviewActive(true);
        observer.disconnect(); // Stop observing once it's active
      }
    }, options);

    if (scrollContainerRef.current) {
      observer.observe(scrollContainerRef.current);
    }

    return () => {
      if (scrollContainerRef.current) {
        observer.unobserve(scrollContainerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (scrollY >= 150) {
      history('/holiday/slides');
    }
  }, [scrollY, history]);

  return (
    <section ref={scrollContainerRef} className={`holiday-overview ${overviewActive ? "active" : ""}`}>
      <h2>Begin exploring our selected charities</h2>
      <div className="instructions" onClick={handleAutoClick}>
        <p>Start Exploring</p>
      </div>
      <div className="container">
        <div className="game-board">
          <animated.div
              className="circle one"
              style={{
                ...circleProps,
              }}
          >
            <img src={KeepAustinBeautiful} />
          </animated.div>
          <div className='circle-dec two'><img src={Lifeworks} /></div>
          <div className='circle-dec three'><img src={InsideBooksProject}/></div>
          <div className='circle-dec four'><img src={CentralTexasFoodBank}/></div>
          <div className='circle-dec five'><img src={RonaldMcDonaldHouse}/></div>
          <div className='circle-dec six'><img src={AustinPetsAlive}/></div>
        </div>
      </div>
    </section>
  )
}
