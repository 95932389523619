import { useEffect, useState } from 'react'
import _ from 'lodash';
import './BeforeAndAfter.scss'

export default function BeforeAndAfter({ content, portfolioItemData }) {
  const [showBefore, setShowBefore] = useState(null);

  const handleToggle = () => {
    setShowBefore(!showBefore);
  };

  if (!content) return null;

  return (
    <section className={`portfolio-before-and-after ${showBefore ? 'before' : 'after'} ${content.extra_space ? 'extra-space' : ''}  ${content.before.body_copy || content.after.body_copy ? 'copy-included' : ''}`} style={{ backgroundColor: showBefore ? content.before.custom_background_color : content.after.custom_background_color }}>
      <div className="container">
        <div className="body-copy">
          {content.before.body_copy || content.after.body_copy ?
            <p>{ showBefore ? content.before.body_copy : content.after.body_copy }</p>
            : null
          }
        </div>
        <div className="comp">
          <img className="image before" src={ content.before.before_image.url } />
          <img className="image after" src={content.after.after_image.url} />
        </div>
        <div className="toggle-container">
          <button onClick={handleToggle}>{showBefore ? "See after" : "See before"}</button>
        </div>
      </div>
    </section>
  )
}
