import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { API_URL } from 'assets';
import _ from 'lodash';
import './PortfolioItem.scss'

import Component from './Component';

import Contact from './Contact/Contact'

export default function PortfolioItem() {
  const params = useParams();
  const [portfolioItemData, setportfolioItemData] = useState(null);

  useEffect(() => {
    const getContent = async () => {
      const postResponse = await fetch(`${API_URL}/portfolio?slug=${params.slug}&_embed=author,wp:term&_fields=id,slug,title,author,acf,content,excerpt,date,_links.wp:term,_embedded.wp:term,_links.author,_embedded.author`);
      const item = await postResponse.json();
      setportfolioItemData(item[0]);
    }
    getContent();
  }, [params.slug]);


  if (!portfolioItemData) return null;

  return (
    <>
      <Helmet bodyAttributes={{ class: 'portfolio sample' }}>
        <title>Our Work | Third Rail Creative</title>
        <meta name="description" content="Helmet application" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"></meta>
      </Helmet>

      {portfolioItemData.acf.portfolio_components.map((component, index) => <Component component={component} portfolioItemData={portfolioItemData} key={index} />)}

      <Contact />

    </>
  )
}
