import Illustration from './assets/illustration-placeholder.png'
import KeepAustinBeautiful from './assets/keep-austin-beautiful.png';
import KeepAustinBeautifulBG from './assets/keep-austin-beautiful-bg.png';
import KeepAustinBeautifulMobile from './assets/c1-mobile-illo.svg';
import KeepAustinBeautifulBoard from './assets/c1-full-color.svg';
import InsideBooksProject from './assets/inside-books-project.png';
import InsideBooksProjectBG from './assets/inside-books-project-bg.png';
import InsideBooksProjectMobile from './assets/c3-mobile-illo.svg';
import InsideBooksProjectBoard from './assets/c3-full-color.svg';
import CentralTexasFoodBank from './assets/central-texas-food-bank.png';
import CentralTexasFoodBankBG from './assets/central-texas-food-bank-bg.png';
import CentralTexasFoodBankMobile from './assets/c4-mobile-illo.svg';
import CentralTexasFoodBankBoard from './assets/c4-full-color.svg';
import Lifeworks from './assets/lifeworks.png';
import LifeworksBG from './assets/lifeworks-bg.png';
import LifeworksMobile from './assets/c2-mobile-illo.svg';
import LifeworksBoard from './assets/c2-full-color.svg';
import AustinPetsAlive from './assets/austin-pets-alive.png';
import AustinPetsAliveBG from './assets/austin-pets-alive-bg.png';
import AustinPetsAliveMobile from './assets/c6-mobile-illo.svg';
import AustinPetsAliveBoard from './assets/c6-full-color.svg';
import RonaldMcDonaldHouse from './assets/ronald-mcdonald-house-central-texas.png';
import RonaldMcDonaldHouseBG from './assets/ronald-mcdonald-house-central-texas-bg.png';
import RonaldMcDonaldHouseMobile from './assets/c5-mobile-illo.svg';
import RonaldMcDonaldHouseBoard from './assets/c5-full-color.svg';

const contentData = [
  {
    name: 'Keep Austin Beautiful',
    image: KeepAustinBeautiful,
    bgImage: KeepAustinBeautifulBG,
    mobileImage: KeepAustinBeautifulMobile,
    boardImage: KeepAustinBeautifulBoard,
    description:'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
    employees: [
      {
        name: 'Mariana',
        title: 'Design Lead',
        quote: 'As an avid hiker and outdoor enthusiast, having clean areas to enjoy an afternoon out with my family means a lot. When parks are well-maintained and litter-free, it enhances the overall experience and encourages more people to appreciate and care for these beautiful public spaces.'
      },
      {
        name: 'Abbey',
        title: 'Interactive Designer',
        quote: 'Parks and other shared outdoor spaces are vital to any community. Living in a city surrounded by natural beauty is a huge part of what makes this city what it is. In a world with fewer free, shared community spaces, I feel it’s important to protect, maintain, and enhance the spaces we share and take care of the natural landscape in Austin.'
      },
      {
        name: 'Mark',
        title: 'Founder & Executive Creative Director',
        quote: 'There\'s something so satisfying about stepping away from our busy downtown office and into Town Lake\'s natural world just a few blocks away. My wife and I, along with our three boys, love to kayak and paddleboard in the mornings while the lake is calm, and we appreciate the clean shoreline and trail, knowing that KAB has made it possible.'
      },
    ],
  },
  {
    name: 'Lifeworks',
    image: Lifeworks,
    bgImage: LifeworksBG,
    mobileImage: LifeworksMobile,
    boardImage: LifeworksBoard,
    description:'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
    employees: [
      {
        name: 'Desiray',
        title: 'Account Executive',
        quote: 'I proudly support Lifeworks Austin in its mission to end youth homelessness. They help to not only provide immediate shelter and support but also work to address the root causes of homelessness among young people. Supporting Lifeworks gives young people experiencing homelessness the resources they need to thrive.'
      },
      {
        name: 'Hanna',
        title: 'Designer',
        quote: 'Lifeworks holds a special place in my heart for the resources and guidance it provides to teens and young adults. As a teenager, I witnessed the many challenges faced by some of my friends who didn\'t have conventional support systems. I am happy to support this cause because I have seen firsthand how critical these resources are and how life-changing Lifeworks\' efforts can be.'
      },
      {
        name: 'Bryan',
        title: 'Managing Partner & Head of Strategy',
        quote: 'My eyes were recently opened to the problem of youth homelessness. I was shocked to learn of the numbers in our own city. Life is filled with overwhelming challenges for kids these days, but being without a stable place to call home, even for a short time, should not be one of them. Homelessness does not mean hopelessness. LifeWorks is an organization providing that foundation for hope.'
      },
    ],
  },
  {
    name: 'Inside Books Project',
    image: InsideBooksProject,
    bgImage: InsideBooksProjectBG,
    mobileImage: InsideBooksProjectMobile,
    boardImage: InsideBooksProjectBoard,
    description:'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
    employees: [
      {
        name: 'James',
        title: 'Head of Design & Interactive',
        quote: 'I believe that reading is a powerful tool for personal growth, education, and empowerment. That is why I chose to support the Inside Books Project, providing inmates with access to literature, information, and opportunities that can enrich their lives and prepare them for re-entry into society.'
      },
      {
        name: 'Chris',
        title: 'Associate Creative Director',
        quote: 'Incarcerated people are all too often neglected or forgotten entirely by society. Resources within prisons are extremely limited, which is why the work IBP does is so important. If we really want to emphasize rehabilitation over punishment, the promotion of literacy and education in prisons is absolutely invaluable.'
      },
      {
        name: 'Irene',
        title: 'Content Strategist',
        quote: 'Everyone is capable and deserving of changing their life for the better with the right mentality, practice, and environment. The work IBP does is integral to giving a chance to, perhaps, some of the people who need it most. Our community reaps the benefits when we see each other as equally human and work to support everyone’s self-betterment.'
      },
    ],
  },
  {
    name: 'Central Texas Food Bank',
    image: CentralTexasFoodBank,
    bgImage: CentralTexasFoodBankBG,
    mobileImage: CentralTexasFoodBankMobile,
    boardImage: CentralTexasFoodBankBoard,
    description:'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
    employees: [
      {
        name: 'Nguyen',
        title: 'Design Lead',
        quote: 'I\'ve attended events and volunteered for the Central Texas Food Bank in the past, so I am no stranger to the services that it provides. Since my husband started working there this year, I\'ve learned so much more about these services, like how they advocate for fresh produce vs. canned goods with their mobile pantries.'
      },
      {
        name: 'Bud',
        title: 'Copywriter',
        quote: 'Growing up, my parents always encouraged me to get involved with charities that make a real difference, and access to food is arguably one of the more important challenges many face today. Access to fresh food is a human right, and I love supporting the Central Texas Food Bank in its mission.'
      },
      {
        name: 'Mason',
        title: 'Developer',
        quote: 'After working in restaurants for several years, seeing the amount of food wasted at the end of every night was always frustrating. It didn\'t take long to catch on to how important the Central Texas Food Bank was for the community in being the local leader in dealing with food insecurities and working tirelessly to deliver meals to those in need.'
      },
    ],
  },
  {
    name: 'Ronald McDonald House Central Texas',
    image: RonaldMcDonaldHouse,
    bgImage: RonaldMcDonaldHouseBG,
    mobileImage: RonaldMcDonaldHouseMobile,
    boardImage: RonaldMcDonaldHouseBoard,
    description:'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
    employees: [
      {
        name: 'Cara',
        title: 'Group Account Director',
        quote: 'When a close friend of mine\'s baby was undergoing medical treatment, the Ronald McDonald House of Central Texas became a beacon of support for their family, offering them a "home​ away from​ home" during a challenging time. As a mother of two young children, I am grateful for the compassionate and nurturing environment this organization provides for children and their families.'
      },
      {
        name: 'Marissa',
        title: 'Account Supervisor',
        quote: 'The Ronald McDonald House of Central Texas was my sorority philanthropy in college, so every year, we would participate in several events and volunteer efforts to support this wonderful organization. It was always an extra special time around the holidays because we would prepare meals and cookies for the families and bring presents to the kids. Seeing the smiles of pure thankfulness and appreciation on the family\'s faces was such a gift to me.'
      },
      {
        name: 'Annie',
        title: 'Account Executive',
        quote: 'The Ronald McDonald House of Central Texas is not just a place to stay; it\'s a haven where families dealing with a seriously ill child can find strength and support. As someone who cares deeply about children and their well-being, I love supporting a cause that brings comfort, resilience, and a sense of community to kids and their families when they need it most.'
      },
    ],
  },
  {
    name: 'Austin Pets Alive!',
    image: AustinPetsAlive,
    bgImage: AustinPetsAliveBG,
    mobileImage: AustinPetsAliveMobile,
    boardImage: AustinPetsAliveBoard,
    description:'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
    employees: [
      {
        name: 'Lauren',
        title: 'Sr. Account Executive',
        quote: 'Austin Pets Alive! holds such a special place in my heart. Because of APA, Austin is the largest No Kill city in the US, thanks to their work with the city and other local animal shelters. They do so much to ensure every animal in need finds a home, like my family\'s dog, Maggie, who is the sweetest nutcase ever!'
      },
      {
        name: 'Jill',
        title: 'Account Executive',
        quote: 'APA! gave us the greatest gift of all! My husband and I were looking for a furry friend, and we found the perfect fit. Our staffy, Hank, has the best personality and the loudest snores. We\'ll be celebrating our first Christmas as a family this year!'
      },
      {
        name: 'Gab',
        title: 'Interactive Designer',
        quote: 'I love APA! because having a dog has always been a part of my upbringing. My family and I have consistently welcomed our furry companions from local animal shelters and organizations. I can\'t imagine life without my silly, loveable black lab, Varick.'
      },
    ],
  },
]


export default contentData;
