import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react'
import { API_URL } from 'assets';
import './Portfolio.scss'

import Hero from './Hero/Hero'
import WorkSamples from './WorkSamples/WorkSamples'
import Experience from './Experience/Experience'
import Contact from './Contact/Contact'

export default function Portfolio() {
  const [portfolioContent, setPortfolioContent] = useState(null);

  useEffect(() => {
    const getPortfolioContent = async () => {
      const response = await fetch(`${API_URL}/pages/42?_embed=true&v=1`);
      const data = await response.json();
      setPortfolioContent(data);
    }
    getPortfolioContent();
  }, [])

  if (!portfolioContent) return null;

  return (
    <>
      <Helmet bodyAttributes={{ class: 'portfolio overview' }}>
        <title>Our Work | Third Rail Creative</title>
        <meta name="description" content="Helmet application" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"></meta>
      </Helmet>

      <Hero data={portfolioContent}/>
      <WorkSamples data={portfolioContent} />
      <Experience data={portfolioContent} />
      <Contact data={portfolioContent}/>
    </>
  )
}
