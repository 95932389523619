import { useEffect, useState } from 'react'
import './DesktopComp.scss'


export default function DesktopComp({ content, portfolioItemData }) {

  if (!content) return null;

  return (
    <section className={`portfolio-desktop-comp ${content.white_copy ? "white-copy" : ''}`} style={{ backgroundColor: content.background_color}}>
      <div className="container">
        {content.headline || content.body_copy ?
          <div className="copy">
            {content.headline ?
              <p className="headline">{content.headline}</p>
              : null
            }
            {content.body_copy ?
              <p className="body" dangerouslySetInnerHTML={{ __html: content.body_copy }}></p>
              : null
            }
          </div>
          : null
        }
        <div className="comp">
          <img src={content.comp_image} alt="desktop comp"/>
        </div>
      </div>
    </section>
  )
}
