
import { useEffect, useState } from 'react'
import './FullWidthImage.scss'

export default function FullWidthImage({ content }) {

  return (
    <div className="full-width-image">
      <div className="title">
        {content.subheader ?
          <h3 dangerouslySetInnerHTML={{__html: content.subheader}}></h3>
        : null
        }
        {content.body_copy ?
          <p dangerouslySetInnerHTML={{__html: content.body_copy}}></p>
        : null
        }
      </div>
      {content.mobile_full_width_image.url ?
        <>
          <img src={content.full_width_image.url} className="desktop" />
          <img src={content.mobile_full_width_image.url} className="mobile" />
        </>
        :
        <img src={content.full_width_image.url} className="no-mobile-set" />
      }
    </div>
  )
}
