
import { useEffect, useState } from 'react'
import './VerticalQuotes.scss'

export default function VerticalQuotes({ content }) {

  return (
    <div className="vertical-quotes">
      <div className="title">
        {content.subheader ?
          <h3 dangerouslySetInnerHTML={{__html: content.subheader}}></h3>
        : null
        }
        {content.body_copy ?
          <p dangerouslySetInnerHTML={{__html: content.body_copy}}></p>
        : null
        }
      </div>
      <div className="quotes-container">
        {content.quotes.map((quote, index) => (
          <div className="quote" key={quote.name_and_title}>
            <div className="headshot">
              <img src={quote.headshot.url} alt={quote.name_and_title}/>
            </div>
            <h3 dangerouslySetInnerHTML={{ __html: quote.name_and_title }}></h3>
            <p dangerouslySetInnerHTML={{__html: quote.quote}}></p>
          </div>
        ))}
      </div>
    </div>
  )
}
