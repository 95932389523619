import { useEffect, useState, createContext } from 'react'

const OptionsContext = createContext();

const OptionsContextProvider = ({ children }) => {
  const [options, setOptions] = useState(null);
  const getOptions = async () => {
    const optionsResponse = await fetch(`https://admin.thirdrailcreative.com/wp-json/acf/v3/options/options`)
    setOptions(await optionsResponse.json())
  }

  useEffect(() => {
    getOptions();
  }, []);

  if (!options) return null;

  return (
    <OptionsContext.Provider value={options.acf}>
      {children}
    </OptionsContext.Provider>
  );
}

export { OptionsContext, OptionsContextProvider };
