import { useEffect, useState } from 'react'
import { API_URL } from 'assets';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import './WorkSamples.scss'

import Arrow from 'assets/Arrow_Up.svg';

export default function Portfolio({ data }) {
  const [content, setContent] = useState([]);
  const [portfolioContent, setPortfolioContent] = useState(null);
  const [clients, setClients] = useState([]);

  useEffect(() => {
    const filteredContent = data.acf.content.filter(item => item.acf_fc_layout === 'portfolio_rows');
    setContent(filteredContent);
  }, [data])

  useEffect(() => {
    const getPortfolioContent = async () => {
      const response = await fetch(`${API_URL}/portfolio?per_page=99`);
      const data = await response.json();
      setPortfolioContent(data);
    }
    getPortfolioContent();
  }, [data])

  useEffect(() => {
    const getClients = async () => {
      const response = await fetch(`${API_URL}/client?per_page=99`);
      const data = await response.json();
      setClients(data);
    }
    getClients();
  }, [data])

  const Row = (projects) => {
    const offset = projects.data.offset_layout ? true : false;
    const offsetSide = projects.data.offset_layout_option;
    const rowCount = projects.data.projects.length;

    if (!portfolioContent) return null;

    return (
      <div className={`row count-${rowCount} ${offset ? 'offset' : ''} ${offsetSide ? offsetSide : ''}`}>
        {
          projects.data.projects.map((project, index) => (
           <Project data={project} key={index} />
          ))
        }
      </div>
    )
  }

  const Project = (project) => {
    const projectData = portfolioContent.filter(item => item.id === project.data.ID);
    const projectClient = clients.filter(client => client.id === projectData[0].client[0]);

    const featuredImage = projectData[0].acf.portfolio_components.filter(component => component.acf_fc_layout === "hero");
    //External link option is temporary. This option should be removed after all 3RC portfolio projects have been moved over to new WP site.
    const externalLink = projectData[0].acf.portfolio_components.filter(component => component.acf_fc_layout === "custom_external_link");

    if (!projectData.length && !projectClient.length) return null;

    return (
      <>
        { externalLink && externalLink.length ?
          <a href={externalLink[0].custom_external_link.url} className="project" style={{ backgroundImage: `url(${featuredImage[0].featured_image.url})` }}>
            <div className="project-information">
              <h4 dangerouslySetInnerHTML={{ __html: projectData[0].title.rendered }}></h4>
              {projectClient.length ?
                <p>{projectClient[0].name}</p>
                : null
              }
            </div>
            <div className="arrow">
              <img src={Arrow} alt="Go to project" />
            </div>
            <div className="overlay"></div>
          </a>
          :
          <Link to={`/work/${projectData[0].slug}`} className="project" style={{ backgroundImage: `url(${featuredImage[0].featured_image.url})` }}>
            <div className="project-information">
              <h4 dangerouslySetInnerHTML={{ __html: projectData[0].title.rendered }}></h4>
              {projectClient.length ?
                <p>{projectClient[0].name}</p>
                : null
              }
            </div>
            <div className="arrow">
              <img src={Arrow} alt="Go to project" />
            </div>
            <div className="overlay"></div>
          </Link>
        }
      </>
    )
  }

  if (!content.length && !portfolioContent && !clients.length) return null;

  return (
    <section className="work-samples">
      <div className="container">
        {content.map((projects, index) => (
          <Row data={projects} key={index}/>
        ))}
      </div>
    </section>
  )
}
