import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import firebase from './../firebase';
import './CharitiesMobile.scss'

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import contentData from './../data';

import Close from './../assets/close-orange.svg';
import Quote from './../assets/quotation-mark.svg';

export default function CharitiesMobile() {
  const firestore = firebase.firestore();
  const history = useNavigate();

  const charityData = contentData;

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 99,
    }
  };

  const [name, setName] = useState('');
  const [selectedCharity, setSelectedCharity] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(null);
  const [submitStatus, setSubmitStatus] = useState(false);



  const handleChange = e => {
    setName(e.target.value);
  };

  const submitDonation = (e) => {
    e.preventDefault();
    setSubmitStatus(true);

    firestore.collection('holiday-gift-2023').add({
      name: name,
      donation: selectedCharity.name,
    });
  }

  const closeModal = () => {
    setModalIsOpen(false);
    setSubmitStatus(false);
    setSelectedCharity(null);
  }


  return (
    <>
        <section className="charities-mobile">
          {charityData.map((charity, index) => (
            <div key={charity.name} className={`slide slide-${index + 1}`}>
              <div className="content">
                <div className="index"><p>0{index + 1}</p></div>
                <div className="illustration">
                  <img src={charity.image} alt={charity.name} className="foreground" />
                  <img src={charity.bgImage} alt={charity.name + 'background'} className="background"/>
                </div>
                <h3>{charity.name}</h3>
              </div>
              <div className="employee-testimonials">
                <Carousel showArrows={true} showStatus={false} infiniteLoop={true} autoPlay={false} interval={10000} showThumbs={false}>
                  {charity.employees.map(employee => (
                    <div className="employee" key={employee.name}>
                      <div className="title"><p>{employee.title}</p></div>
                      <div className="name"><p>{employee.name}</p></div>
                      <div className="quote"><p><img src={Quote}/>{employee.quote}"</p></div>
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
          ))
          }
          <div className="slide slide-7">
            <div className="container">
              <h2>Click to select a charity for your donation</h2>
              <div className="options">
                {charityData.map((charity, index) => (
                  <div key={charity.name} className={`option option-${index + 1}`} onClick={() => { setModalIsOpen(true); setSelectedCharity(charity) }}>
                    <div className="illustration"><img src={charity.mobileImage} /></div>
                    <h4>{charity.name}</h4>
                    <div className={`selection-indicator ${selectedCharity && selectedCharity.name === charity.name ? "selected" : ""}`}></div>
                  </div>
                ))}
              </div>
            </div>

            {selectedCharity ?
              <Modal
                  isOpen={modalIsOpen}
                  onRequestClose={closeModal}
                  style={customStyles}
                  contentLabel="Example Modal"
                  ariaHideApp={false}
                  className="modal mobile"
                  backdrop="static"
                  shouldCloseOnOverlayClick={false}
              >
                {!submitStatus ?
                  <>
                    <button onClick={closeModal} className="close"><img src={Close} alt="Close modal" /></button>
                    <div className="modal-content">
                      <div className="selection">
                      <div className="illustration">
                          <img src={selectedCharity.bgImage} />
                          <img src={selectedCharity.image} />
                        </div>
                        <div className="selection-name">
                          <p>{selectedCharity.name}</p>
                        </div>
                      </div>
                      <form>
                        <label>
                          Donation by
                            <input type="text" name="name" placeholder="Enter your name or leave blank" onChange={handleChange} />
                          </label>
                          <input type="submit" value="Donate" className="submit-donation" onClick={submitDonation} />
                        </form>
                    </div>
                  </>
                  :
                  <>
                    <div className="modal-content">
                      <div className="selection">
                      <div className="illustration">
                          <img src={selectedCharity.bgImage} />
                          <img src={selectedCharity.image} />
                        </div>
                        <div className="selection-name">
                          <p>{selectedCharity.name}</p>
                        </div>
                      </div>
                      <h3>Thank you.</h3>
                      <p>Third Rail Creative will be placing a donation on your behalf! <br></br><br></br> With gratitude and holiday cheer,<br></br><br></br> Your Friends at Third Rail Creative</p>
                      <a className="exit-experience" href="https://thirdrailcreative.com/">Close</a>
                    </div>
                  </>
                }
              </Modal>
              : null
            }
          </div>
        </section>
    </>
  )
}
