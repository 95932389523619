import { useEffect, useState } from 'react'
import _ from 'lodash';
import './MobileComps.scss'

export default function MobileComps({ content, portfolioItemData }) {

  if (!content) return null;

  return (
    <section className="portfolio-mobile-comps" style={{backgroundImage: `url(${content.background_image})`}}>
      <div className="container">
        {content.comps.map(comp => (
          <img src={comp.image} key={comp.image} className="comp" alt="mobile comp"/>
        ))}
      </div>
    </section>
  )
}
